<template>
    <section
        class="navbar-layout flex-direction-column"
        v-show="skinStore.skin"
    >
        <el-config-provider :locale="zhCn">
            <div class="navbar navbar-wrap flex">
                <div class="logo flex-ver-center">
                    <img :src="userStore.platform_logo" alt="监测平台" class="logo-image" />
                    <div class="nav-title">
                        <span class="block text-center font-bolder font-14">{{ userStore.platform_title }}</span>
                        <span class="block text-center font-bolder font-14">{{ userStore.platform_subtitle }}</span>
                    </div>
                </div>
                <el-menu :default-active="data.activeIndex" class="navbar-menu flex-dynamic-full" mode="horizontal" router
                    @select="fn.handleSelect">
                    <el-menu-item index="/monitor">实时监测</el-menu-item>
                    <el-menu-item index="/overview">数据概览</el-menu-item>
                    <el-menu-item index="/analyse">分析数据</el-menu-item>
                    <el-menu-item index="/device">设备数据</el-menu-item>
                    <el-menu-item index="/video">视频信息</el-menu-item>
                    <el-menu-item index="/history/event">告警信息</el-menu-item>
                </el-menu>
                <section class="navbar-right flex">
                    <!-- <el-dropdown trigger="click" class="skin-drop">
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item v-for="(value, key) in skinStore.skinConfig"
                                    @click="skinStore.setSkin({ type: key, save: true })">
                                    <span :style="{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '4px',
                                        border: '1px solid #cfcfcf',
                                        backgroundColor: value.color
                                    }"></span>
                                    <span style="padding-left:10px">{{ value.name }}</span>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                        <section class="flex-ver-center">
                            <section class="font-12">主题色：</section>
                            <section class="flex-dynamic-full scroll-hidden flex-ver-center skin-box">
                                <span class="block-color" :style="{
                                    backgroundColor: skinStore.currentSkinConfig?.color
                                }"></span>
                                <span style="padding-left:10px" class="font-12">{{ skinStore.currentSkinConfig?.name }}</span>
                            </section>
                        </section>
                    </el-dropdown> -->
                    <section class="flex-ver-center m-r-20">
                        <section @click="fn.goEventPage" class="cursor-pointer">
                            <el-badge :value="alarmCount > 99 ? '99+' : alarmCount" class="item">
                                <img src="@/assets/images/icon/bell.png" alt="" />
                            </el-badge>
                        </section>
                    </section>
                    <el-dropdown trigger="click" class="userinfo-drop">
                        <template #dropdown>
                            <el-dropdown-menu>
                                <router-link to="/">
                                    <el-dropdown-item>首页</el-dropdown-item>
                                </router-link>
                                <el-dropdown-item>
                                    <span style="display: block">修改密码</span>
                                </el-dropdown-item>
                                <el-dropdown-item divided>
                                    <router-link style="display: block" to="/logout">退出登录</router-link>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                        <section class="flex-all-center">
                            <div class="avatar-wrapper flex-all-center">
                                <img src="~~/assets/images/avatar.png" class="user-avatar" />
                            </div>
                            <ClientOnly>
                                <div class="username text-ellipsis flex-dynamic-full">
                                    {{ data.username }}
                                </div>
                            </ClientOnly>
                        </section>
                    </el-dropdown>
                </section>
            </div>
            <section class="main-content flex-dynamic-full scroll-hidden">
                <slot />
            </section>
            <AlarmNotification />
        </el-config-provider>
    </section>
</template>
<script>
import AlarmNotification from '@/views/common/alarm.vue';
export default {
    components: {
        AlarmNotification
    }
}
</script>
<script setup>

import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import { ref, reactive, watch, computed } from "vue";
import pageinfo from '~~/config/pageinfo';
import { useUserStore } from '~~/store/user';
import { useSkinStore } from '~~/store/skin';
import { useEventStore } from '~~/store/event';

const router = useRouter();
const route = useRoute();
const userStore = useUserStore();
const skinStore = useSkinStore();
const eventStore = useEventStore();
useHead({
    title: pageinfo[route.name]?.title || '光伏数据监测系统'
})

const data = reactive({
    activeIndex: route.path,
    username: '',
});
const alarmCount = computed(() => {
    return eventStore.eventList?.total?.length || 0;
})
const fn = {
    goEventPage() {
        router.push('/events');
    },
    checkMenus() {

    },
    checkSkin() {
        if (!skinStore.skin) {
            skinStore.getSkin();
        }
    },
    closeDialogHandle(flag) {
        data.dialogType = '';
        data.dialogShow = false;
        data.dialogData = null;
        if (flag) {
            fn.fetchTableData();
        }
    },
    openDialogHandle(info, type = 'solution') {
        data.dialogType = type;
        data.dialogData = info;
        data.dialogShow = true;
    },
};
onMounted(() => {
    if (process.client) {
        data.username = userStore.userinfo?.account?.name;
        fn.checkSkin();
    }
})
</script>

<style lang="scss" scoped>
a {}
</style>